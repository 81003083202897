import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import * as Yup from "yup";
import { useState } from "react";
import { Switch, Typography } from "@mui/material";
import { createPredictAch, fetchPdf } from "./store/predictAchSlice";
import { useAppDispatch } from "app/withAppProviders";
import { useSelector } from "react-redux";
import Coursel from "./caoursel";

function PredictAchForm() {
  const dispatch = useAppDispatch();
  const [imagesCarousel, setImageCarousel] = useState(false);
  const rowData = useSelector((state: any) => state.predictAch.items);

  const imageUrl = process.env.REACT_APP_IMAGES_URL;
  const rowDataId = rowData.id;

  const [showOperatingRoomTempOptions, setShowOperatingRoomTempOptions] =
    useState(false);

  const toggleOperatingRoomTempOptions = () => {
    setShowOperatingRoomTempOptions((prevValue) => !prevValue);
  };

  const onSubmit = async (w) => {
    setImageCarousel(false);
    await dispatch(createPredictAch(w));
    if (rowData) {
      setImageCarousel(true);
    }
  };

  const saveResult = async() => {
    if (rowData && rowDataId) {
      await dispatch(fetchPdf(rowDataId));
    }
  };

  const Images = [
    {
      id: 1,
      url:
        rowData.position_of_outlets === 'A'
          ? 'https://images-airflowsurgical-prime.s3.amazonaws.com/admin/prediction_walkthrough_outlet_A.png'
          : rowData.position_of_outlets === 'B'
          ? 'https://images-airflowsurgical-prime.s3.amazonaws.com/admin/prediction_walkthrough_outlet_B.png'
          : rowData.position_of_outlets === 'C'
          ? 'https://images-airflowsurgical-prime.s3.amazonaws.com/admin/prediction_walkthrough_outlet_C.png'
          : `${imageUrl}/admin/result_1.png`,
      text: "",
    },
    {
      id: 2,
      url: `${imageUrl}/admin/result_2.png`,
      text: "",
    },
    {
      id: 3,
      url: `${imageUrl}/admin/result_3.png`,
      text: "",
    },
  ];

  const form: TSFForm = {
    onSubmit,
    saveResult,
    rowDataId,
    fields: [
      {
        name: "particle_size",
        label: "Particle Size",
        type: FieldType.StaticSelect,
        reduxObjectName: "particle_size",
        validation: Yup.string()
          .required("Please select particle size")
          .typeError("Required!"),
        options: [
          // { id: 3, name: "3: Bacteria" },
          { id: 2.5, name: "2.5: EPA Listed Air Hazard" },
          { id: 1, name: "1: Bacteria" },
          { id: 0.7, name: "0.7: Largest Wildfire particle size" },
          { id: 0.5, name: "0.5: Largest COVID virus size" },
          { id: 0.4, name: "0.4: Smallest Wildfire size" },
          { id: 0.1, name: "0.1: Smallest COVID virus size" },
          // { id: 0.045, name: "0.045: Zika virus size" },
        ],
      },
      {
        name: "position_of_lights",
        label: "Position of Lights",
        type: FieldType.StaticSelect,
        reduxObjectName: "position_of_lights",
        validation: Yup.string()
          .required("Please select position of lights")
          .typeError("Required!"),
        options: [
          { id: "head", name: "Head" },
          { id: "torso", name: "Torso" },
          { id: "feet", name: "Feet" },
          { id: "head_feet", name: "Head & Feet" },
          { id: "head_torso", name: "Head & Torso" },
          { id: "feet_torso", name: "Feet & Torso" },
          { id: "head_torso_feet", name: "Head, Torso & Feet" },
        ],
      },
      {
        name: "operating_room_temp",
        label: "Operating Room Temperature",
        type: FieldType.StaticSelect,
        reduxObjectName: "operating_room_temp",
        validation: Yup.string()
          .required("Please select operating room temperature")
          .typeError("Required!"),
        options: showOperatingRoomTempOptions
          ? [
            { id: "68", name: "68°F" },
            { id: "69", name: "69°F" },
            { id: "70", name: "70°F" },
            { id: "71", name: "71°F" },
            { id: "72", name: "72°F" },
          ]
          : [
            { id: "68", name: "20°C" },
            { id: "69", name: "20.555°C" },
            { id: "70", name: "21.111°C" },
            { id: "71", name: "21.666°C" },
            { id: "72", name: "22.222°C" },
          ],
      },
      {
        name: "position_of_outlets",
        label: "Position of Outlets",
        type: FieldType.StaticSelect,
        reduxObjectName: "position_of_outlets",
        validation: Yup.string()
          .required("Please select operating room temperature")
          .typeError("Required!"),
        options: [
          { id: "A", name: "Walls Parallel to Patient (Head and Feet) = A" },
          { id: "B", name: "Walls Parallel to patient (Torso) = B" },
          {
            id: "C",
            name: "Walls Perpendicular to Patient (Head and Feet) = C",
          },
        ],
      },
    ],
  };

  return (
    <>
      <div
        style={{
          marginLeft: "15%",
          marginRight: "15%",
          marginBottom: "5%",
          marginTop: "5%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          className="mb-10"
        >
          <Switch
            checked={showOperatingRoomTempOptions}
            onChange={toggleOperatingRoomTempOptions}
            sx={{
              "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked": {
                color: "black",
              },
              "&.MuiSwitch-root .MuiSwitch-track": {
                backgroundColor: "gray",
              },
            }}
          />
          <Typography style={{ marginLeft: 8, marginTop: 10 }}>
            {showOperatingRoomTempOptions
              ? "Operating Room Temp (F)"
              : "Operating Room Temp (C)"}
          </Typography>
        </div>

        <SFForm {...form}></SFForm>

        {rowData && imagesCarousel && (
          <>
            <div>
              <Typography fontSize={22}>
                Predicted ACH : {rowData.ACH}
              </Typography>

              <Typography fontSize={22}>
                Accuracy : {rowData.accuracy?.toFixed(3)}
              </Typography>
            </div>
            <Coursel images={Images} maxSteps={Images.length} />
          </>
        )}
      </div>
    </>
  );
}

export default PredictAchForm;
